import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import {
  createInterviewStageActionColumn,
  createInterviewStageNameColumn,
  createInterviewStageRecommendationColumn,
  createInterviewStageResultColumn,
} from '@src/constants/columns/candidateInterviewStages'
import {
  InterviewRoundInterface,
  CandidateInterviewStageTableRowType,
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
  InterviewType,
  ScheduleSidebarModeType,
  CandidateSidebarTypes,
} from '@src/interfaces/interviewTool'
import { Box, Color, Flex, Icon, Link, Text, Token } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import pluralize from 'pluralize'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { NoStagesBanner } from '@src/pages/Forms/Candidate/StagesTable/NoStagesBanner'

interface Props {
  round: InterviewRoundInterface
  onClick?: (
    data: CandidateInterviewStageTableRowType,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
    actionType?: CandidateSidebarTypes,
  ) => void
  onRefresh?: () => void
  selectedItemId?: number
  hideCount?: boolean
  disableActions?: boolean
  canViewEditOffer: boolean
  stages: InterviewStageWithoutRoundInterface[]
  candidateId: number
}

interface RowProps
  extends Pick<
    Props,
    | 'onRefresh'
    | 'canViewEditOffer'
    | 'stages'
    | 'selectedItemId'
    | 'candidateId'
    | 'disableActions'
  > {
  canCancel: boolean
  canAddFeedback: boolean
  roundId: number
  onTriggerAction: Props['onClick']
  currentStageId: number
  seniority: SeniorityInterface | null
}

const ROW = ({
  stages,
  roundId,
  selectedItemId,
  currentStageId,
  onTriggerAction,
  canCancel,
  canAddFeedback,
  onRefresh,
  canViewEditOffer,
  disableActions,
  candidateId,
  seniority,
}: RowProps): RowInterface<CandidateInterviewStageTableRowType> => ({
  noChildrenRequest: true,
  highlight: data => {
    if ('children' in data) {
      return ''
    }
    return selectedItemId === data.id
      ? Token.color.actionBackground
      : Token.color.groupedBackground
  },
  isChildrenOpener: (data: CandidateInterviewStageTableRowType) => 'children' in data,
  cells: [
    {
      ...createInterviewStageNameColumn(currentStageId),
      width: 245,
    },
    {
      ...createInterviewStageRecommendationColumn(seniority),
      width: 50,
    },
    {
      ...createInterviewStageResultColumn(stages, currentStageId),
      width: 120,
    },
    ...(!disableActions && (canCancel || canAddFeedback)
      ? [
          {
            ...createInterviewStageActionColumn({
              canAddFeedback,
              canCancel,
              candidateId,
              canViewEditOffer,
              currentStageId,
              roundId,
              stages,
              onRefresh,
              onTriggerAction,
            }),
            width: 35,
          },
        ]
      : []),
  ],
})

export const StagesTable = ({
  round,
  onClick,
  selectedItemId,
  onRefresh,
  hideCount = false,
  disableActions = false,
  canViewEditOffer,
  stages,
  candidateId,
}: Props) => {
  const canCancel = !!round.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )

  if (!stages.length || !round.latest_interview_stage) {
    return <NoStagesBanner roundId={round.id} onRefresh={onRefresh} />
  }

  const feedbacks = stages.map(item => {
    return {
      ...item,
      recommendations: item.interview_feedbacks.reduce<InterviewToolInterviewer[]>(
        (acc, feedback) => {
          if (feedback.result && feedback.interviewer) {
            acc.push(feedback.interviewer)
          }
          return acc
        },
        [],
      ),
      children: item.interview_feedbacks,
    }
  })

  const mainJobPosting = round?.application
  const jobPosting = mainJobPosting?.job_posting
  const jobPostingName = jobPosting?.name
  const specialisation = round.specialisation
  const processTitle = jobPostingName || specialisation.name
  const processLink = pathToUrl(
    jobPosting ? ROUTES.FORMS.JOB_POSTING.PREVIEW : ROUTES.FORMS.SPECIALISATIONS.PREVIEW,
    jobPosting
      ? { id: jobPosting.id, specId: specialisation.id }
      : { id: specialisation.id },
  )

  return (
    <Box>
      <AdjustableTable<CandidateInterviewStageTableRowType>
        renderCount={count => (
          <Text color={Color.GREY_TONE_50}>
            Showing {count} {pluralize('Hiring stage', count)} for{' '}
            <Link
              to={processLink}
              use={InternalLink}
              target="_blank"
              color="inherit"
              display="inline-block"
            >
              <Flex alignItems="center">
                {processTitle}&nbsp;
                <Icon name="LinkExternal" size={14} />
              </Flex>
            </Link>
          </Text>
        )}
        name={TableNames.CandidateHiringStages}
        dataType="Hiring stage"
        row={ROW({
          canCancel,
          canAddFeedback,
          onRefresh,
          stages,
          roundId: round.id,
          canViewEditOffer,
          onTriggerAction: onClick,
          selectedItemId,
          currentStageId: round.latest_interview_stage.id,
          disableActions,
          candidateId,
          seniority: round.seniority,
        })}
        onRowClick={(row, parentIndexes) => {
          onClick?.(row, undefined, feedbacks[parentIndexes[0]]?.interview_type)
        }}
        noDataMessage="There are no interviews for this candidate"
        noReset
        enableSettings={false}
        hideCount={hideCount}
        rowHeight="large"
        count={feedbacks?.length}
        data={feedbacks}
        lockFirstColumn={false}
        expandableType="chevron"
        childrenOpenByDefault
        childrenOpenByRowClick={false}
      />
    </Box>
  )
}
