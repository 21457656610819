import { goalsListTableRequests } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { PerformanceSelector } from '@src/interfaces/performance'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useEffect, useState } from 'react'

const useFetchGoalsCount = (
  initialFilters: FilterByInterface[] | null,
  selectedPeriod: Pick<
    PerformanceSelector,
    'category' | 'probation_reviews_deliverables_type' | 'offset' | 'id'
  >,
) => {
  const [isFetching, setIsFetching] = useState(false)
  const [count, setCount] = useState(0)
  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    const fetchCount = async () => {
      if (initialFilters) {
        try {
          setIsFetching(true)
          const result = await (
            await goalsListTableRequests.getItems({ filters: initialFilters })
          ).data.count
          setCount(result)
        } catch (e) {
          showStatusPopup({
            title: 'Something went wrong',
            description: getStringMessageFromError(e),
            status: 'error',
          })
        } finally {
          setIsFetching(false)
        }
      }
    }

    fetchCount()
  }, [initialFilters, selectedPeriod])

  return { count, isFetching }
}

export default useFetchGoalsCount
