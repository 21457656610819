import React from 'react'
import { Box, Flex, Icon, Progress, Token, Text } from '@revolut/ui-kit'
import {
  ColoredPercent,
  getPercentColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import GraphIconChart, {
  fetchDataType,
} from '@src/components/Charts/GraphIconChart/GraphIconChart'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { isNumber } from 'lodash'

interface ProgressCellProps {
  value: number
  strikeThroughValue?: number
  infoTooltip?: string
  graph?: {
    fetchFunction: fetchDataType
    id: number
  }
}

export const ProgressCell = ({
  value,
  graph,
  strikeThroughValue,
  infoTooltip,
}: ProgressCellProps) => {
  const percent = value * 100
  const color = getPercentColor(percent)
  return (
    <Flex flexDirection="column" gap="s-4">
      <Flex gap="s-8" alignItems="center" justifyContent="flex-end">
        {infoTooltip && (
          <Tooltip placement="top" text={infoTooltip}>
            <Box>
              <Icon name="InfoOutline" size={14} />
            </Box>
          </Tooltip>
        )}
        {graph && (
          <Box>
            <GraphIconChart id={graph.id} fetchData={graph.fetchFunction}>
              <Icon name="BarChart" size={16} color={Token.color.greyTone20} />
            </GraphIconChart>
          </Box>
        )}
        {isNumber(strikeThroughValue) && (
          <Text
            style={{
              textDecoration: 'line-through',
            }}
            color={Token.color.greyTone20}
          >
            {strikeThroughValue.toFixed(2)}%
          </Text>
        )}
        <ColoredPercent percent={percent} alignSelf="end" />
      </Flex>

      <Progress value={value} color={color} />
    </Flex>
  )
}
