import React from 'react'
import { EmployeeGoal } from '@src/interfaces/employees'
import { formatDate } from '@src/utils/format'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { selectorKeys } from '@src/constants/api'
import { ActionButton, Flex } from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface GoalRowData {
  name: string
  date?: Date | null
}

const row: RowInterface<GoalRowData> = {
  cells: [
    {
      type: CellTypes.text,
      idPoint: 'name',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Goal name',
      width: 300,
    },
    {
      type: CellTypes.insert,
      idPoint: 'date',
      dataPoint: 'date',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Due',
      insert: ({ data }) => {
        return data.date ? formatDate(data.date) : ''
      },
      width: 170,
    },
  ],
}

type Props = {
  goals?: EmployeeGoal[]
  type?: ReviewCycleCategory
  onAddGoalRedirect?: () => void
  isLineManager?: boolean
}

export const ProbationLayoutGoalsJiraDisabled = ({
  goals,
  type,
  onAddGoalRedirect,
  isLineManager,
}: Props) => {
  const data = goals?.map(el => ({ name: el.name, date: el.deadline_date_time })) || []

  return (
    <AdjustableTable<GoalRowData>
      name={TableNames.ProbationGoals}
      row={row}
      count={data.length}
      data={data}
      hideCountAndButtonSection
      emptyState={
        <Flex style={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <EmptyTableRaw
            imageId="3D304"
            title={
              type === ReviewCycleCategory.Probation
                ? 'Probation goals pending'
                : 'PIP goals pending'
            }
            description={
              type === ReviewCycleCategory.Probation
                ? 'Line Manager needs to provide probation goals'
                : 'Line Manager needs to provide PIP goals'
            }
            action={
              type &&
              isLineManager && (
                <ActionButton useIcon="Plus" onClick={onAddGoalRedirect}>
                  Add {type === ReviewCycleCategory.Probation ? 'probation' : 'PIP'} goals
                </ActionButton>
              )
            }
          />
        </Flex>
      }
    />
  )
}
