import React, { useEffect, useMemo, useState } from 'react'
import { isFuture, isPast } from 'date-fns'
import { Flex, IconButton, ProgressStep, ProgressSteps, Token } from '@revolut/ui-kit'
import { CheckpointType, ProbationCheckpoints } from '@src/interfaces/probationReview'
import {
  FinalGrade,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import {
  getStartOfCheckpoint,
  getStepDescription,
  getStepState,
  getStepTitle,
} from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'
import { formatDate } from '@src/utils/format'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FeatureFlags } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { createDraftGoal } from '@src/api/goals'
import { captureException } from '@sentry/react'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { ProbationStepDetails } from './ProbationStepDetails'

type Props = {
  data: EmployeeInterface
  checkpoints: ProbationCheckpoints
  endDate: string
  requests?: PerfReviewRequestFeedbackInterface[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
  performanceLink?: string
  finalGrade?: FinalGrade
}

const progressStepTitle: { [key: string]: string } = {
  [ReviewCategory.Probation]: 'End of probation',
  [ReviewCategory.PIP_V2]: 'End of PIP',
}

export const ProbationLayoutTimeline = ({
  data,
  checkpoints,
  endDate,
  requests,
  employeeId,
  cycleId,
  reviewCategory,
  performanceLink,
  finalGrade,
}: Props) => {
  const [summaryCheckpoint, setSummaryCheckpoint] = useState<
    ReviewSummaryInterface | undefined
  >()
  const user = useSelector(selectUser)
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const [selectedStepId, setSelectedStepId] = useState<number | string | null>(null)
  const showStatusPopup = useShowStatusPopup()

  const { navigateWithEntity } = useOrgEntity()
  const { data: contentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const fetchSummary = async () => {
    const result = await getPerformanceReviewSummary(
      cycleId,
      employeeId,
      reviewCategory,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: 'line_manager',
              name: 'line_manager',
            },
            {
              id: 'functional_manager',
              name: 'functional_manager',
            },
          ],
        },
      ],
    )

    setSummaryCheckpoint(result.data)
  }

  useEffect(() => {
    fetchSummary()
  }, [])
  const timelineSteps = [
    ...checkpoints.checkpoints,
    ...(checkpoints.decision_checkpoints ? checkpoints.decision_checkpoints : []),
  ]
  let currentTimelineSteps = timelineSteps.filter(
    event =>
      getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline) === 'pending',
  )
  if (!currentTimelineSteps.length) {
    currentTimelineSteps = timelineSteps
      .filter(
        event => getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline),
        'default',
      )
      .slice(0, 1)
  }

  const formattedEndDate = formatDate(endDate, 'dd MMM')

  const getFinalStepDescription = () => {
    if (isPast(new Date(endDate))) {
      return `Closed ${formattedEndDate}`
    }

    if (isFuture(new Date(endDate))) {
      return `Opens ${formattedEndDate}`
    }

    return `Closes ${formattedEndDate}`
  }

  const onClickAddGoals = async () => {
    if (reviewCategory === ReviewCategory.Probation && goalsEnabled) {
      try {
        setIsCreateGoalPending(true)
        const response = await createDraftGoal({
          owner: { id: data.id },
          content_type: contentTypes?.find(({ model }) => model === 'employees'),
          object_id: data.id,
        })
        navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
          reviewCycleId: cycleId,
          isNew: true,
        })
      } catch (err) {
        captureException(err)

        showStatusPopup({
          status: 'error',
          title: 'Failed to create goal',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      } finally {
        setIsCreateGoalPending(false)
      }
    } else {
      navigateTo(
        pathToUrl(
          reviewCategory === ReviewCategory.Probation
            ? ROUTES.FORMS.PROBATION_GOALS
            : ROUTES.FORMS.PIP.GOAL_SETTINGS,
          {
            employeeId,
            cycleId,
            id: cycleId,
          },
        ),
      )
    }
  }

  const getLastStepState = () => {
    if (isFuture(new Date(endDate))) {
      return 'default'
    }

    if (isPast(new Date(endDate))) {
      return 'done'
    }

    return 'pending'
  }

  const lastStepState = getLastStepState()

  const timeLineMap = useMemo(() => {
    const map = new Map()
    timelineSteps?.forEach(step => {
      map.set(step.id, step)
    })
    return map
  }, [timelineSteps])

  const pendingTimeLineStepData = useMemo(() => {
    if (lastStepState === 'pending') {
      return { type: CheckpointType.Final, checkpoint: null }
    }
    const targetCheckpoint = timelineSteps.filter(checkpoint => {
      const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
      const state = getStepState(startOfCheckpoint, checkpoint.checkpoint_deadline)
      return state === 'pending'
    })[0]
    return { type: targetCheckpoint?.checkpoint_type, checkpoint: targetCheckpoint }
  }, [checkpoints])

  const activeTimeLineStep = useMemo(() => {
    if (typeof selectedStepId === 'number') {
      const checkpoint = timeLineMap.get(selectedStepId)
      return { type: checkpoint?.checkpoint_type, checkpoint }
    }
    if (selectedStepId === 'Final') {
      return { type: CheckpointType.Final, checkpoint: null }
    }
    return pendingTimeLineStepData
  }, [selectedStepId, pendingTimeLineStepData])

  const isLineManager = user.id === data.line_manager?.id

  const canAddGoals =
    (reviewCategory === ReviewCategory.PIP_V2 || isLineManager) &&
    pendingTimeLineStepData?.type === CheckpointType.Goals
  return (
    <>
      <ProgressSteps>
        {timelineSteps.map(checkpoint => {
          const title = getStepTitle(checkpoint, reviewCategory)
          const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
          const state = getStepState(startOfCheckpoint, checkpoint.checkpoint_deadline)
          const description = getStepDescription(checkpoint)
          return (
            <ProgressStep
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedStepId(checkpoint.id)}
              paddingTop="s-6"
              paddingBottom="s-16"
              borderRadius="r6"
              indicatorColor={state === 'pending' ? Token.color.accent : Token.color.teal}
              bg={selectedStepId === checkpoint.id ? Token.color.greyTone8 : undefined}
              key={checkpoint.id}
              state={state}
            >
              <ProgressStep.Title>{title}</ProgressStep.Title>
              <ProgressStep.Description>{description}</ProgressStep.Description>
            </ProgressStep>
          )
        })}

        <ProgressStep
          indicatorColor={
            lastStepState === 'pending' ? Token.color.accent : Token.color.teal
          }
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedStepId('Final')}
          paddingTop="s-6"
          paddingBottom="s-16"
          borderRadius="r6"
          bg={
            selectedStepId === 'Final' || lastStepState === 'pending'
              ? Token.color.greyTone8
              : undefined
          }
          state={lastStepState}
        >
          <ProgressStep.Title>{progressStepTitle[reviewCategory]}</ProgressStep.Title>
          <ProgressStep.Description>{getFinalStepDescription()}</ProgressStep.Description>
        </ProgressStep>
      </ProgressSteps>
      {selectedStepId && activeTimeLineStep?.type && (
        <Flex
          borderRadius="r16"
          padding="s-16"
          mt="s-12"
          bg={Token.color.greyTone8}
          flexDirection="column"
        >
          <Flex style={{ flexGrow: 1 }} justifyContent="flex-end">
            <IconButton
              onClick={() => setSelectedStepId(null)}
              useIcon="Cross"
              size={16}
            />
          </Flex>
          <ProbationStepDetails
            finalGrade={finalGrade}
            isCreateGoalPending={isCreateGoalPending}
            onClickAddGoals={onClickAddGoals}
            canAddGoals={canAddGoals}
            goalsStatus={timelineSteps[0]?.goals?.status}
            lineManager={data.line_manager}
            summaryCheckpoint={summaryCheckpoint}
            requests={requests}
            performanceLink={performanceLink}
            type={activeTimeLineStep.type}
            checkpoint={activeTimeLineStep.checkpoint}
            reviewCategory={reviewCategory}
            cycleId={cycleId}
            employeeId={employeeId}
          />
        </Flex>
      )}
    </>
  )
}
