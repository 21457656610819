import React from 'react'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getKpiGradeColumn } from '@src/constants/columns/kpi'
import { GradesMapInterface } from '@src/utils/grades'
import {
  KPIsCardTableProps,
  TableWrapper,
} from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { EntityTypes } from '@src/constants/api'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { useTable } from '@components/Table/hooks'
import { goalsListTableRequests, GoalsStats } from '@src/api/goals'
import {
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
} from '@src/constants/columns/goals'
import isNumber from 'lodash/isNumber'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { GoalsInterface } from '@src/interfaces/goals'
import { Flex } from '@revolut/ui-kit'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { PermissionTypes } from '@src/store/auth/types'
import { AddGoalAction } from '@src/features/Goals/components/AddGoalAction'
import { NameIdInterface } from '@src/interfaces/requisitions'
import { NoPersonalGoalsBanner } from './NoPersonalGoalsBanner'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

export const getGoalsRow: (
  gradesMap: GradesMapInterface,
  noWidget?: boolean,
) => RowInterface<GoalsInterface> = (gradesMap, noWidget) => ({
  cells: [
    {
      ...goalsNameColumn(goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })),
      width: noWidget ? 150 : 300,
    },
    {
      ...goalsWeightColumn,
      width: noWidget ? 92 : 60,
    },
    {
      ...goalsOwnerColumn,
      width: 80,
    },
    {
      ...goalsProgressColumn,
      width: 110,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})
interface EmployeeGoalsCardTableContentProps extends KPIsCardTableProps {
  initialFilters: FilterByInterface[]
  isTeamGoals?: boolean
  team?: NameIdInterface
  isPerformanceBanner?: boolean
  isFetching?: boolean
}

export const EmployeeGoalsCardTableContent = ({
  initialFilters,
  setGoalsStat,
  isPerformanceBanner,
  setGoalsList,
  noWidget,
  gradesMap,
  team,
  isFetching,
}: EmployeeGoalsCardTableContentProps) => {
  const initialSort = [
    { sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC },
  ]

  const table = useTable(goalsListTableRequests, initialFilters, initialSort, {
    parentIdFilterKey: 'parent_id',
  })

  if (isNumber(table.stats?.overall_progress) && !!setGoalsStat) {
    setGoalsStat(table.stats?.overall_progress)
  }

  if (table.data && !!setGoalsList) {
    setGoalsList(table.data)
  }

  const { entity } = useOrgEntity()
  const canAddGoals =
    entity?.data.field_options.permissions?.includes(PermissionTypes.CanAddGoals) || false
  return (
    <TableWrapper noWidget={noWidget}>
      <Flex gap="s-12" flexDirection="column" style={{ flexGrow: 1 }}>
        {team && table.data.length > 0 && (
          <NoPersonalGoalsBanner isPerformanceBanner={isPerformanceBanner} team={team} />
        )}
        <AdjustableTable<GoalsInterface, GoalsStats>
          name={TableNames.Goals}
          dataType="Goal"
          row={getGoalsRow(gradesMap, noWidget)}
          {...table}
          loading={table.loading || isFetching}
          onRowClick={data =>
            navigateTo(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: data.id }))
          }
          noReset
          hideCountAndButtonSection
          expandableType="chevron"
          useFetchedChildren
          emptyState={
            <Flex style={{ flexGrow: 1 }} justifyContent="center">
              <EmptyTableRaw
                imageId="3D304"
                title="No goals"
                description="Manager needs to provide goals"
                action={canAddGoals && <AddGoalAction />}
              />
            </Flex>
          }
        />
      </Flex>
    </TableWrapper>
  )
}

export const EmployeeGoalsCardTable = connect(
  ({ noWidget = false, employeeId, cycle, ...props }: KPIsCardTableProps) => {
    const contentTypeFilter = useGetGoalContentTypeFilter(EntityTypes.employee)

    if (!contentTypeFilter) {
      return null
    }

    const initialFilters: FilterByInterface[] = [
      ...(cycle?.id
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: String(cycle.id), name: String(cycle.name) }],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      contentTypeFilter,
      ...(employeeId
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: String(employeeId), name: String(employeeId) }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
    ]

    return (
      <EmployeeGoalsCardTableContent
        initialFilters={initialFilters}
        {...props}
        noWidget={noWidget}
      />
    )
  },
)
